const siteMetadata = {
  title: `Nick Macioce`,
  description: `Nick Macioce is a DevOps Engineer`,
  author: `Nick Macioce`,
  siteUrl:
    process.env.NODE_ENV === 'development'
      ? `https://dev.nickmacioce.com`
      : `https://nickmacioce.com`,
  image: `/static/og/default.png`,
  twitterUsername: `@ndmacioce`,
}

export { siteMetadata }
